import { LinkBaseProps } from '@mui/material/Link'
import Link, { LinkProps } from 'next/link'
import React from 'react'

/**
 * We need to Omit from the MUI Button the {href} prop
 * as we have to handle routing with Next.js Router
 * so we block the possibility to specify an href.
 */

export type ButtonLinkProps = Omit<LinkBaseProps, 'href' | 'classes'> &
  Pick<LinkProps, 'href' | 'as' | 'prefetch'>

const AncorLink = React.forwardRef<ButtonLinkProps, any>(
  ({ href, prefetch, ...props }, ref) => (
    <Link
      href={href}
      prefetch={prefetch}
      passHref={true}
      ref={ref}
      {...props}
    ></Link>
  ),
)

AncorLink.displayName = 'AncorLink'

export { AncorLink }
