import { Button, Grid, Link, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NovaTextLogoIcon } from '@novafuturltd/shared'
import React from 'react'
import { useIntl } from 'react-intl'
import Lottie from 'react-lottie'
import { HelpButton } from '../../components/help/button';
import { ButtonLink } from '../../components/wrappers/Button'
import { AncorLink } from '../../components/wrappers/Link'
import animationData from '../../public/image/404/404Image.json'
import { BorderedContainer } from '../../shared/v2/bordered-container'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '80vh',
      width: '100%',
    },
    paper: {
      padding: theme.spacing(4),
      zIndex: 2,
      height: '383px',
      width: '324px',
    },

    container: {
      paddingBottom: '0px !important',
    },

    staticB: {
      content: '""',
      height: '100%',
      backgroundImage: 'url("/image/404/novaBG.png")',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '12px',
      display: 'flex',
      backgroundPosition: 'center',
      '&:after': {
        content: '""',
        backgroundImage: 'url("/image/404/grainTexture2BG.gif")',
        height: '80vh',
        display: 'flex',
        width: '100%',
        position: 'absolute',
        borderRadius: '12px',
        opacity: '0.1',
        justifyContent: 'center',
        // animation: `$animateGrain 8s steps(10) infinite`,    : for static image animation if gif is to heavy.
        backgroundPosition: 'center',
        zIndex: 1,
      },
    },

    '@keyframes animateGrain': {
      '0%, 100%': {
        transform: 'translate(0, 0)',
      },
      '10%': {
        transform: 'translate(-5%, -10%)',
      },
      '20%': {
        transform: 'translate(-15%, -20%)',
      },
      '30%': {
        transform: 'translate(-5%, -10%)',
      },
      '40%': {
        transform: 'translate(-15%, -20%)',
      },
      '50%': {
        transform: 'translate(-5%, -10%)',
      },
      '60%': {
        transform: 'translate(-15%, -20%)',
      },
      '70%': {
        transform: 'translate(-5%, -10%)',
      },
      '80%': {
        transform: 'translate(-15%, -20%)',
      },
      '90%': {
        transform: 'translate(-5%, -10%)',
      },
      '100%': {
        transform: 'translate(-15%, -20%)',
      },
    },
  }),
)

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const FourZeroFour = ({ code }: { code?: string }) => {
  const classes = useStyles({})
  const intl = useIntl()
  return (
    <BorderedContainer paddingBottom="0px" minHeight={false}>
      <Grid
        className={classes.root}
        container={true}
        data-testid="404-page"
        direction={'row'}
      >
        <div className={classes.staticB}>
          <Grid
            style={{ zIndex: 3 }}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            item={true}
          >
            <Grid container={true} justifyContent="center" spacing={10}>
              <Lottie options={defaultOptions} />
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} lg={4} item={true}>
            <Grid container={true} justifyContent="center">
              <Paper className={classes.paper}>
                <NovaTextLogoIcon style={{ width: '220px', height: '34px' }} />
                <Typography
                  style={{ fontSize: '86px', lineHeight: '106px' }}
                  variant="h1"
                >
                  {code ? code : 404}
                </Typography>
                {code === undefined && (
                  <Typography
                    style={{ lineHeight: '32px' }}
                    paragraph={true}
                    variant="subtitle1"
                  >
                    {intl.formatMessage({ id: 'page404.description' })}
                  </Typography>
                )}
                {code === '503' && (
                  <Typography
                    style={{ lineHeight: '32px' }}
                    paragraph={true}
                    variant="subtitle1"
                  >
                    Something went wrong. Please raise a ticket{' '}
                    <Link
                      color="primary"
                      href="https://novafutur.atlassian.net/servicedesk/customer/portals"
                      target="_blank"
                      component={AncorLink}
                      underline="hover">
                      here
                    </Link>
                    .
                  </Typography>
                )}

                <p>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    component={ButtonLink}
                    href="/"
                    data-test="backButton"
                  >
                    {intl.formatMessage({ id: 'page404.backButton' })}
                  </Button>
                </p>
                <HelpButton />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </BorderedContainer>
  );
}

export default FourZeroFour
