import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Button, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import { useMixPanel } from '../../contexts/mixpanel'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 68,
      height: 24,
      borderRadius: 100,
      background: NovaColours.RP050[theme.palette.mode],
      fontSize: 12,
      color: theme.palette.common.white,
      '&:hover': {
        background: NovaColours.RP050[theme.palette.mode],
      },
    },
    icon: {
      width: 16,
      height: 16,
      fill: theme.palette.common.white,
    },
  }),
)

export const HelpButton = () => {
  const classes = useStyles({})
  const mxp = useMixPanel()

  const handleOpenLink = (
    url: string,
    newWindow: boolean,
    id: string,
  ) => () => {
    mxp.track('User Clicks Dashboard Widget', {
      id,
      window: newWindow ? 'new' : 'existing',
    })
    if (newWindow) {
      return window.open(url, '_blank')
    }
    return window.open(url, '_self')
  }

  return (
    <Button
      onClick={handleOpenLink(
        'https://logifuture-jira.atlassian.net/servicedesk/customer/portal/6',
        true,
        'service-desk',
      )}
      className={classes.root}
      size="small"
      startIcon={<HelpOutlineIcon className={classes.icon} />}
    >
      HELP
    </Button>
  )
}
